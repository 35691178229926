import React, { forwardRef } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const MaterialCommunityYouTube = forwardRef(
  (
    { fillColor = '#212121', ...props }: { fillColor?: string } & SvgProps,
    ref
  ) => (
    <Svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      ref={ref}
      {...props}
    >
      <Path
        d='M10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83-.25.9-.83 1.48-1.73 1.73-.47.13-1.33.22-2.65.28-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44-.9-.25-1.48-.83-1.73-1.73-.13-.47-.22-1.1-.28-1.9-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83.25-.9.83-1.48 1.73-1.73.47-.13 1.33-.22 2.65-.28 1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44.9.25 1.48.83 1.73 1.73z'
        fill={fillColor}
      />
    </Svg>
  )
)

export default MaterialCommunityYouTube
